import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import translate from './../../Translate/resetPassword.json';
import { Success } from './SucsessModal';
const ChangePasswordProfile = () => {
    const navigate = useNavigate();

    const [language, setLanguage] = useState('');
    useEffect(() => {
      setLanguage(JSON.parse(localStorage.getItem('choosen-language')));
    }, [])

    const [sucModal,setSucModal] = useState(false);
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [errorPasses, setErrorPasses] = useState('');

    const handleForm2Change = (e) => {
        setNewPass(e.target.value);
    }
    const handleForm3Change = (e) => {
        setConfirmPass(e.target.value);
    }
    const handleCheckButton = () => {
        if (confirmPass === ''|| newPass === '') {
            setErrorPasses('Please fill the forms');
        } else if(confirmPass !== newPass) {
            setErrorPasses("You didn't confirmed new password");
        }else {
            setErrorPasses('');
            resetPassword();
        }

    }
    async function resetPassword() {
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      const token = url.searchParams.get('token');
      const email = url.searchParams.get('email');
      const domain = url.hostname;
      const dataToSend = {
        email: email,
        token: token,
        password: newPass, 
      };
      console.log(dataToSend, JSON.stringify(dataToSend) );
      const apiUrl = `https://${domain}/api/user/reset_passwd`;
      const requestOptions = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)  
      };
    
      try {
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        setSucModal(true);

      } catch (error) {
        console.error('Ошибка при запросе к API:', error);
      }
    }

    return(
        <div>
            <div className='main for-log'>
            {sucModal && <Success language={language}/>}
                <div className='log'>
                    <div className='logs'>
                        <h2 className='main-pages-titles'>{translate.change.title[language]}</h2>
                        <p className='inputs'>
                          <input 
                          type="password" 
                          className='form' 
                          placeholder={translate.change.placeholder1[language]}
                          value={newPass}
                          onChange={handleForm2Change} />
                        </p>
                        <p className='inputs'>
                          <input 
                          type="password" 
                          className='form' 
                          placeholder={translate.change.placeholder2[language]} 
                          value={confirmPass}
                          onChange={handleForm3Change} />
                        </p>
                        {errorPasses && <p className="error-message">{errorPasses}</p>}
                        
                        <p className='inputs'>
                            <button style={{
                                width: '100%'
                            }} className='grid-log login-accept-btn' onClick={handleCheckButton}>{translate.change.button[language]}</button>
                        </p>
                    </div>
                </div> 
            </div> 
        </div>
    )
}

export default ChangePasswordProfile;