import { useState, useEffect } from "react";
import {useLoading} from './../LoadModal/script'
export const usePayment = ({ currencies, domain, minAmount }) => {
  const { loader } = useLoading();
  const [errMsg, setErrMsg] = useState(false);
  const [modal, setModal] = useState(false);
  const state = () => {
    setModal(!modal);
  };

  const [amount, setAmount] = useState(0.00);
  const handleChangeInput = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)){
      setAmount(parseFloat(value.toFixed(2)));
    }
  };
  useEffect(() => {
    setAmount(parseInt(minAmount));
  }, [minAmount])

  const [currency, setCurrency] = useState("");
  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
  };
  useEffect(() => {
    if (currencies.length > 0) {
      setCurrency(currencies[0]);
    }
  }, [currencies]);

  const pay = async () => {
    if (isNaN(amount)) setErrMsg(true);
    else if (amount <= 0) setErrMsg(true);
    else{
      const dataToSend = {
        amount: amount,
        currency: currency,
        lang: 'EN',
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      };
      loader(true);
      try {
        const response = await fetch(`https://${domain}/api/bank/pay`, requestOptions);
        if (!response.ok) throw new Error();
        const data = await response.json();
        if (data?.url) {
          window.location.href = data.url;
        }else{
          state();
        }
      } catch (e) {
        console.error(e);
      }finally{
        setErrMsg(false);
        loader(false);
      }
    }
  };

  return {
    currency,
    modal,
    amount,
    errMsg,
    state,
    handleChangeInput,
    handleChangeCurrency,
    pay,
  };
};
