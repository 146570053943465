import { useInvoice } from "./Script"

export const Invoice = ({translate, language, domain}) => {
    const currencies = ["USD", "GEL", "EUR"];
    const {
        currency,
        modal,
        state,
        handleChangeInput,
        handleChangeCurrency,
        downloadPDF
    } = useInvoice({currencies, domain});

    return (
        <div>
            <button className="bank-transfer-btn" onClick={state}>{translate.prase2[language]}</button>
            {modal && (
                <div className="modal-window">
                  <div className="error-block">
                    <div className="error-massage">
                      <div className="block-to-flex-column">
                        <label htmlFor="input">Amount:</label>
                        <input type="text" onChange={handleChangeInput} />
                        <label htmlFor="select">Currency:</label>
                        <select onChange={handleChangeCurrency} value={currency}>
                          {currencies.map((option, index) => (
                            <option key={index}>{option}</option>
                          ))}
                        </select>
                      </div>
                      <button className="error-btn" onClick={downloadPDF}>
                        Download Invoice
                      </button>
                      <button className="error-btn" onClick={state}>
                        {translate.prase20[language]}
                      </button>
                    </div>
                  </div>
                </div>
            )}
        </div>
    )
}
