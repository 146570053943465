import React from 'react';
import Routers from './app/Router';

// import Header from './app/components/main-menu/Instruments/Header';
import { BrowserRouter as Router } from 'react-router-dom';
import { WebSocketProvider } from './WebSocketContext';
import { LoadingProvider } from './app/components/user-menu/LoadModal/script';

function App() {
  return (
    <div className="App">
      <WebSocketProvider>
        <LoadingProvider>
          <Router>
            <Routers/>
          </Router>
        </LoadingProvider>
      </WebSocketProvider>
    </div>
  );
}

export default App;