import logo from './../imgs/logo1.png'
import menu from './../imgs/menu.png'
import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import translate1 from './../../../Translate/profilePage.json';
import translate2 from './../../../Translate/balancePage.json'
const Header = () => {
    const navigate = useNavigate();
    const navigateTo = (path) => {
      navigate(path);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null);

    const closeOnOutsideClick = (e) => {
      if (isModalOpen && !e.target.closest('.user-btn-menu')) {
        closeModal();
      }
    };

    const openModal = () => {
      setIsModalOpen(true);
      document.body.style.overflow = 'hidden';
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      document.body.style.overflow = 'auto';
    };

    const handleEscKey = (event) => {
        if (event.key === 'Escape') {
          closeModal();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscKey);
        document.addEventListener('click', closeOnOutsideClick);
        return () => {
          document.removeEventListener('keydown', handleEscKey);
          document.removeEventListener('click', closeOnOutsideClick);
        }
    });
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const domain = url.hostname; 
    const logOut = async () => {
      try {
        const apiUrl = `https://${domain}/api/user/logout`;
        const requestOptions = {
          method: 'POST'
        };
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
      }
    };
    

    return (
      <div className="user-navbar" ref={modalRef}>
        <div className='left-align'>
          <button className='title-btn' onClick={() => {navigateTo('/main')}}><img src={logo} alt="Логотип" className="user-logo" /></button>
          <button className='user-nav-btns' onClick={() => {navigateTo("../balance")}}> {translate2.name[JSON.parse(localStorage.getItem('choosen-language'))]}</button> 
          <button className='user-nav-btns' onClick={() => {navigateTo("../profile")}}> {translate1.name[JSON.parse(localStorage.getItem('choosen-language'))]}</button> 
        </div> 
        <div className='right-align'>   
          <button className='user-nav-btns logout' onClick={() => {navigateTo('/main/login'); logOut(); }}>Logout</button> 
        </div>    
        <button className="user-btn-menu" onClick={() => openModal()}>
          <img src={menu} alt="menu" className='user-btn-menu-img'/>
        </button> 

        {isModalOpen && (
          <div className="user-modal-overlay">
              <div className="menu user-modal-content">
                  <button className='user-nav-menu-btns' onClick={() => {navigateTo("../balance"); closeModal()}}>{translate2.name[JSON.parse(localStorage.getItem('choosen-language'))]}</button>
                  <button className='user-nav-menu-btns' onClick={() => {navigateTo("../profile"); closeModal()}}>{translate1.name[JSON.parse(localStorage.getItem('choosen-language'))]}</button>
                  <hr width="100%" color="#FAFAFA" />
                  <button className='user-nav-menu-btns' onClick={() => {navigateTo('/main/login'); logOut(); closeModal();}}>Logout</button>
              </div>
          </div>
        )}
      </div>
    );
  };
  
  export default Header;