import React, { useState, useRef,useEffect } from 'react';
import plus from "./../imgs/plus.png";

const NumberInputList = ({translate, language}) => {
    const [decoderList, setDecoderList] = useState([]);
    const [deletable, setDeletable] = useState([]);

    const regionRefs = useRef([]);
    const inputRefs = useRef([]);
    const [regionList, setRegionList] = useState([]);
    const [inputList, setInputList] = useState([]);
    const [readOnlyStatus, setStatus] = useState([]);

    const [modal, showModal] = useState(false);
    const [indexOfInput, setIndex] = useState(false);
    const [submitModal, showSubmitModal] = useState(false);
    const [reasonModal, showReasonModal] = useState(false);

    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const domain = url.hostname; 
    
    ///////////////////////////////
    //fetch POST
    const decoderOpperations = async (opperation, decoder, index) => {  
        const dataToSend = {
            decoder_id: decoder
        }
        const apiUrl = `https://${domain}/api/user/decoders`;
        const requestOptions = {
            method: opperation,
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(dataToSend) 
        }
        try{
            const respond = await fetch(apiUrl, requestOptions);
            if(!respond.ok){
                handleRemoveInput(index);
                showModal(true);
                throw new Error('not ok');
            }
            const statusList = [...readOnlyStatus];
            statusList[index] = true;
            setStatus(statusList);            
        }
        catch(error){
            console.error('Errer : ', error);
        }
    } 

    /////////////////////////////////////////////////////////////
    //adding new input
    const handleAddInput = () => {
      setRegionList([...regionList, '']);
      setInputList([...inputList, '']);
      setStatus([...readOnlyStatus, false]);
    };

    /////////////////////////////////////////////////////////////
    //saving values
    const regionFormChange = (index, value) => {
        const updated = [...regionList];
        updated[index] = value;
        setRegionList(updated);
        if(updated[index].length >= 2) regionRefs.current[index].blur();
    }
    const focusNext = (index) => {
        inputRefs.current[index].focus();
    }
    const handleInputChange = (index, value) => { 
        const updatedInputList = [...inputList];
        updatedInputList[index] = value;
        setInputList(updatedInputList);
    };

    /////////////////////////////////////////////////////////////
    //removing inputs from system or just from the interface
    const handleRemoveInput = (index) => {
        if(readOnlyStatus[index] === true) decoderOpperations('DELETE', regionList[index] + "-" + inputList[index], index); 

        const updated = [...regionList];
        const updatedInputList = [...inputList];
        const read = [...readOnlyStatus];
        
        updated.splice(index, 1);
        updatedInputList.splice(index, 1);
        read.splice(index, 1);

        setRegionList(updated);
        setInputList(updatedInputList);
        setStatus(read);
    };

    /////////////////////////////////////////////////////////////
    //removing опечатки
    function check_decoder_id(decoder_id) {
        const nn = decoder_id.split('-');
        if(nn.length != 2) return false;
        const id_crc = (nn[1] >>> 0) & 0xff;
        const id = (nn[0]<< 24) | (nn[1] >>> 8);
        let crc = 0;
          for(let i = 0; i < 4; i++) {
              crc ^= (id >>> (i*8)) & 0xff;
              for(let j = 0; j < 8; j++) {
                  if((crc & 0x80) != 0) crc = ((crc << 1) ^ 7);
                  else crc <<= 1;
              }
          }
        crc &= 0xff;
        return crc === id_crc;
    }
    
    /////////////////////////////////////////////////////////////
    //saving changes
    const blurr = (event, index, value) => {
        event.preventDefault();
        const updated = [...readOnlyStatus];
        if(inputRefs.current[index].value.length < 1) handleRemoveInput(inputList.length - 1);
        else if(updated[index] === false){
            if(regionList.length > decoderList.length) {
                const dat = regionList[index] + "-" + value;
                if(check_decoder_id(dat)){ 
                    decoderOpperations('PUT', dat, index);
                    updated[index] = true;
                }else{
                    handleRemoveInput(index);
                    showModal(true);
                }
            }
        }
        setStatus(updated);
        setIndex(index);
    }
    const handleEnterPress = (event, index, value) => {
        if (event.key === 'Enter') {
            blurr(event, index, value);
        }
    };

    const stopScrolling = () => {
        window.scrollTo({top: 0});
    }
    const openModals = (index) => {
        !deletable[index] ? showSubmitModal(true) : showReasonModal(true);
        stopScrolling()
    }


    /////////////////////////////////////////////////////////////
    //decoderList from api
    const fetchDecoders = async () => {
        try{
            const respose = await fetch(`https://${domain}/api/user/decoders`);
            if(!respose.ok){
                throw new Error('not ok');
            }
            const data = await respose.json();
           
            const decs = data.decoders || [];
            const updateDeletable = decs?.map(decoder => decoder.packs?.some(pack => pack.expire !== null && pack.expire !== undefined ));
            setDecoderList(decs);
            setDeletable(updateDeletable);
        }
        catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        fetchDecoders();
    }, [])

    useEffect(() => {
        const conveterAndSaver = () => {
            const updatedDec = [];
            const updatedReg = [];
            for(let i = 0; i < decoderList.length; i++) {
                const regordec = decoderList[i].decoder_id.split("-");
                updatedReg.push(regordec[0]);
                updatedDec.push(regordec[1]);     
            };
            setRegionList(updatedReg);
            setInputList(updatedDec);
            setStatus(decoderList.map(elem => true))
        }
        conveterAndSaver();
    }, [decoderList])
    
    /////////////////////////////////////////////////////////////
    return (
        <div>
            {inputList && inputList.map((input, index) => (
                <div className='inputList' key={index}>
                  <input
                    type="text" 
                    className="region-input" 
                    autoFocus
                    ref={(el) => (regionRefs.current[index] = el)}
                    onBlur={() => {focusNext(index)}}
                    onChange={(e) => regionFormChange(index, e.target.value)}
                    value={regionList[index]}
                    readOnly={readOnlyStatus[index]} />
                  -  
                  <input
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      className="decoder-input"
                      value={input}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      onKeyDown={(e) => handleEnterPress(e, index, input)}
                      onBlur={(e) => {blurr(e, index, input)}}                 
                      readOnly={readOnlyStatus[index]}      
                  />
                  <button onClick={() => {openModals(index)}}>{translate.prase14[language]}</button>
                </div>
            ))}
            {submitModal && 
            <div className="modal-window">
                <div className="error-block"> 
                    <div className="error-massage">
                        <h1>{translate.prase16[language]}</h1>
                        <p>{translate.prase17[language]}</p>
                        <button className="error-btn" onClick={() => {handleRemoveInput(indexOfInput); showSubmitModal(false)}}>Ok</button>
                        <button className="error-btn" onClick={() => {showSubmitModal(false)}}>{translate.prase18[language]}</button>
                    </div>
                </div>
            </div>}
            {reasonModal && 
            <div className="modal-window">
            <div className="error-block"> 
                <div className="error-massage">
                    <h1>Error</h1>
                    <p>You already payed for this decoder</p>
                    <button className="error-btn" onClick={() => {showReasonModal(false)}}>Ok</button>
                </div>
            </div></div>}
            {modal && 
            <div className="modal-window">
            <div className="error-block"> 
                <div className="error-massage">
                    <h1>Error</h1>
                    <p>There is no decoder with this number or it is already in use</p>
                    <button className="error-btn" onClick={() => {showModal(false)}}>Retry</button>
                </div>
            </div></div>}
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}><button onClick={() => {handleAddInput()}} className="plus-btn"><img src={plus} alt="plus" /></button>add decoder</div>
        </div>
    );
};

export default NumberInputList;

