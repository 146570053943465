import logo from './../imgs/logo.png';
import globe from './../imgs/globe.png';
import enter from './../imgs/enter.png';
import user from './../imgs/user.png';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState} from 'react';
import AccordionItem from './AccordionItem';

const Header = ({signStatus, getLanguage}) => {
  const navigate = useNavigate();

  const openNewTab = () => {
    if(signStatus) {
      navigate('/main/profile');
    } else {
      navigate('/main/login');
    }
  };

  //accordion showing
  const [accordion, showAccordion] = useState(false);

  const handleToogleAccordion = () => {
    if(!accordion) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    showAccordion(!accordion)
  }

  useEffect(() => {
    const handleHide = (event) => {
      if (accordion && !event.target.closest('.accordion') && !event.target.closest('.language-choise')){
        showAccordion(false)
        document.body.style.overflow = 'auto';
      }
    }

    document.addEventListener('click', handleHide);
    return () => {
      document.removeEventListener('click', handleHide);
    }
  })

  //accordion position
  const Ref = useRef(null);
  const [pos, setPos] = useState('0');
  const calculateLeftPosition = () => {
    if (Ref.current) {
      const buttonRect = Ref.current.getBoundingClientRect();
      const position = buttonRect.left - 65;
      return `${position}px`;
    }
    return '0';
  };
  useEffect(() => {
    setPos(calculateLeftPosition())
  }, [accordion])

  //accordion connecting

  const land = ['eng', 'rus', 'ukr', 'geo', 'arm', 'tuk', 'fas', 'fra', 'kaz'];
  const country = ['English', 'Русский', 'Українська', 'ქართული', 'Հայոց', 'Türkmen', 'زبان فارسي', 'Français', 'Қазақша'];
  const logtext = ['Log in', 'Войти', 'Увійти', 'Log in', 'Log in', 'Log in', 'Log in', 'Log in', 'Log in'];
  const proftext = ['Profile', 'Профиль', 'Профіль', 'პროფილი', 'Անձնագիր','Profil', 'پروفایل', 'Profil', 'Profil'];
  const [savedLanguage, setSavedLanguage] = useState(0);
  const handleChooseLanguage = (index) => {
    setSavedLanguage(country[index]);
    localStorage.setItem('choosen-language', JSON.stringify(land[index]));
    getLanguage(land[index]);
  }
  
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('choosen-language'));
    if(data === null) {
      localStorage.setItem('choosen-language', JSON.stringify('eng'));
      setSavedLanguage(country[land.indexOf('eng')]);
    }else{
      setSavedLanguage(country[land.indexOf(data)]);
    }
  }, [])

  return (
    <div className="main-navbar">         
      <h1 className='main-title'>
        <img src={logo} alt="Логотип" className="main-logo" />
        Global Link LLC
      </h1>
      <button ref={Ref} onClick={handleToogleAccordion} className='language-choise'><img src={ globe } alt="" /> {savedLanguage} </button>
      {accordion && 
        <div className="accordion" style={{left: pos}}>
          {country.map((lan,index) => <AccordionItem title={lan} indexSave={handleChooseLanguage} index={index}/>)}
        </div>
      }
      <button onClick={openNewTab} className='main-nav-btn showen'>{signStatus ? proftext[country.indexOf(savedLanguage)] : logtext[country.indexOf(savedLanguage)]}</button>
      <button onClick={openNewTab} className='main-nav-btn hidden'><img src={signStatus ? user: enter} alt="enter" /></button>
    </div>
  );
};

export default Header;
