import React, { useState, useEffect }  from "react";
import Header from "./Instruments/Header";
import DecoderAdder from "./Instruments/DecoderAdder";
import CountryList from "./Instruments/CountryList";
import { useNavigate } from "react-router-dom";

import logo from './LoadModal/logo.png'
import translate from './../../Translate/profilePage.json'

const Profile = () => {
    const navigate = useNavigate()
    const nav = () => {
      navigate('/main');
    }

    const [language, setLanguage] = useState('');
    
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const domain = url.hostname; 
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [email, setEmail] = useState("")
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("")
    const [country, setCountry] = useState("");
    const [number, setNumber] = useState("");
    const [loading, setLoading] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    }
    const handleSurnameChange = (e) => {
        setSurname(e.target.value);
    }
    const handleNumberChange = (e) => {
        setNumber(e.target.value);
    }
    const countryListSave = (param) => {
      setCountry(param);
    }    
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://${domain}/api/user`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEmail(data.email);
        setName(data.name);
        setSurname(data.surname);
        setCountry(data.country);
        setNumber(data.phone);
        setLoading(false);
      } catch (error) {
        nav();
      }
    };

    async function fillUserInfo() {
      const dataToSend = {
        phone: number || '',
        name: name || '',
        surname: surname || '',
        country: country || '',
        timezone: timezone,
      };
      console.log(dataToSend, JSON.stringify(dataToSend));
      const apiUrl = `https://${domain}/api/user`;
      const requestOptions = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)  
      };
    
      try {
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        fetchUser();
      } catch (error) {
        console.error('Ошибка при запросе к API:', error);
      }
    }
    useEffect(() => {
      setLanguage(JSON.parse(localStorage.getItem('choosen-language')))
      fetchUser();
    }, [navigate]); 
    return(
        <div className="user-menu-profile">
            <Header />
            <div className="user-body">
                {loading && <div style={{backgroundColor: "#FAFAFA", opacity: 0.8, width: "100vw", height: "100vh", overflow:"hidden", position: "fixed", top: 0, left: 0, zIndex: 1000}}>
                  <img src={logo} alt="Логотип" style={{position: "absolute", top: "50%", left: "50%",  transform: "translate(-50%, -50%)"}} />
                </div> }
                <div className="user-profile-body-in">
                  <div className="user-profile-block">
                      <div className="user-profile-block-container">
                          <div className="user-profile-right-column">
                              <p className="columnize">Email: {email}</p>
                              <p className="columnize">{translate.prase1[language]} <input 
                                  className="user-info-input"
                                  type="text" 
                                  value={name} 
                                  onChange={handleNameChange}/>
                              </p>
                              <p className="columnize">{translate.prase2[language]} <input 
                                  className="user-info-input"
                                  type="text" 
                                  value={surname} 
                                  onChange={handleSurnameChange}/>
                              </p>
                              <CountryList saveCountry={countryListSave} countryOnLoad={country || ''} multilingua={translate.prase3[language]} language={language}/>
                              <p className="columnize">{translate.prase4[language]} <input 
                                  className="user-info-input"
                                  type="text" 
                                  value={number}  
                                  onChange={handleNumberChange}/>
                              </p>

                          </div>
                          <div className="user-profile-right-column">
                              <p><a href="password-change">{translate.prase5[language]}</a></p>
                              <p>{translate.prase6[language]} </p>
                              <DecoderAdder translate={translate} language={language}/>
                              <p><button className="user-profile-save-btn" onClick={() => {fillUserInfo();}}>{translate.prase7[language]}</button></p>
                          </div>
                      </div>
                  </div>
                </div>
            </div>  
        </div>
    )
}
export default Profile;