import { useNavigate } from "react-router-dom";
import translate from './../../../Translate/loginRegister.json'; 
export const Success = ({language}) => {
    const navigate = useNavigate();
    return(
        <div className="modal-window">
            <div className="error-block"> 
                <div className="error-massage">
                    <h1>{translate.register.message.title[language]}</h1>
                    <p>password changed</p>
                    <button className="error-btn" onClick={() => {navigate('/main/login')}}>{translate.register.message.button[language]}</button>
                </div>
            </div>
        </div>
    )
}