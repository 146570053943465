import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import HomeBtn from './Instruments/HomeBtn';
import Cookies from 'js-cookie';
import translate from './../../Translate/loginRegister.json';

const Login = ({putdec, dcr_id}) => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const [language, setLanguage] = useState('');
  useEffect(() => {
    setLanguage(JSON.parse(localStorage.getItem('choosen-language')));
  }, [])

  const [form1Value, setForm1Value] = useState('');
  const [form2Value, setForm2Value] = useState('');
  const [errorForm1, setErrorForm1] = useState('');
  const [errorForm2, setErrorForm2] = useState('');

  const handleForm1Change = (e) => {
      setForm1Value(e.target.value);
  }

  const handleForm2Change = (e) => {
      setForm2Value(e.target.value);
  }
  
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const domain = url.hostname; 
  async function getAccess() {
    const dataToSend = {
      username: form2Value,
      password: form1Value
    };
    const apiUrl = `https://${domain}/api/user/login`;
    const requestOptions = {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)  
    };
  
    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      Cookies.remove('password');
      navigateTo("../profile");
      putdec(dcr_id);
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      setErrorForm2('Wrong email');
      setErrorForm1('Or wrong password');
    }
  }
    const handleCheckButton = () => {
        let isValidForm1 = true;
        let isValidForm2 = true;

        if (form1Value === '') {
            isValidForm1 = false;
            setErrorForm1('Complete the form');
        } else {
            setErrorForm1('');
        }

        if (form2Value === '') {
            isValidForm2 = false;
            setErrorForm2('Complete the form');
        } else {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (!emailPattern.test(form2Value)) {
                isValidForm2 = false;
                setErrorForm2('Not an email');
            } else {
                setErrorForm2('');
            }
        }
        if(isValidForm1 === true && isValidForm2 === true){
          getAccess();
        }
  }

  const passwordRef = {};
  const handleEnterClick = (e) => {
    if(e.key === 'Enter'){
      passwordRef.current.focus();
    }
  }

  return (
    <div>
      <div className='main for-log'>
        <HomeBtn/>
        <div className='log'>
          <div className='logs'>
          <h2 className='main-pages-titles'>Log In</h2>
          <p className='inputs'>
            <input 
            type="text" 
            autoFocus
            onBlur={() => passwordRef.current.focus()}
            onKeyDown={(event) => handleEnterClick(event)}
            className='form' 
            placeholder={translate.login.placeholder1[language]} 
            value={form2Value}
            onChange={handleForm2Change}/>
          </p>
          {errorForm2 && <p className="error-message">{errorForm2}</p>}
          <p className='inputs'>
            <input 
            ref={(el) => (passwordRef.current = el)}
            type="password" 
            className='form' 
            placeholder={translate.login.placeholder2[language]}  
            value={form1Value}
            onChange={handleForm1Change}
            onBlur={handleCheckButton}
            onKeyDown={(event) => { 
              if (event.key === 'Enter')handleCheckButton();
            }} />
          </p>
          {errorForm1 && <p className="error-message">{errorForm1}</p>}
          <button className='pass-change-btn' onClick={() => {navigateTo("../change-password-request")}}>{translate.login.link[language]} </button>
          <div className='log-container'>
            <button className='grid-log login-accept-btn' onClick={() => {handleCheckButton()}}>{translate.login.button1[language]} </button> 
            <button className='grid-log register-route-btn' onClick={() => {navigateTo("../register")}}>{translate.login.button2[language]} </button>
          </div>
          </div>
        </div> 
      </div>
    </div>
  );
};
  
export default Login;