import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import translate from './../../Translate/profilePage.json';
const ChangePasswordProfile = () => {
    const navigate = useNavigate();
    const navigateTo = (path) => {
        navigate(path);
    };
    const [language, setLanguage] = useState('');
    const [currentPass, setCurrentPass] = useState('');

    const [newPass, setNewPass] = useState('');

    const [confirmPass, setConfirmPass] = useState('');
    const [errorPasses, setErrorPasses] = useState('');

    const handleForm1Change = (e) => {
        setCurrentPass(e.target.value);
    }
    const handleForm2Change = (e) => {
        setNewPass(e.target.value);
    }
    const handleForm3Change = (e) => {
        setConfirmPass(e.target.value);
    }

    const handleCheckButton = () => {
        if (confirmPass === ''|| currentPass === '' || newPass === '') {
            setErrorPasses('Please fill the forms');
        }else if(confirmPass !== newPass){
            setErrorPasses("You didn't confirm new password");
        } else {
            setErrorPasses('');
            passwordChange()
        }
    }

    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const domain = url.hostname; 
    const passwordChange = async () => {
        const dataToSend = {
            password: currentPass,
            new_password: newPass,
        } 
        const apiUrl = `https://${domain}/api/user/passwd`;
        const requestOptions = {
            method: 'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(dataToSend) 
        };
        try{
            const response = await fetch(apiUrl, requestOptions);
            if(!response.ok){
                throw new Error('Network response was no ok')
            }
            navigateTo("../profile");
        }catch(error){
            console.error('Ошибка при запросе к API:', error)
            setErrorPasses('Wrong current password');
        }
    }

    const getAccess = async () => {
        try{
            const response = await fetch(`https://${domain}/api/user`);
            if (!response.ok) {
                throw new Error()
            };
        }catch(error) {
            navigate('/main')
        }
    }

    useEffect(() => {
        const func = () => {
            setLanguage(JSON.parse(localStorage.getItem('choosen-language')));
            getAccess();
        }
        func();
    });

    return(
        <div>
            <div className='main for-log'>
            {/* <HomeBtn/> */}
                <div className='log'>
                    <div className='logs'>
                        <h2 className='main-pages-titles'>{translate.prase8[language]}</h2>
                        <p className='inputs'>
                          <input 
                          type="password" 
                          className='form' 
                          placeholder={translate.prase9[language]} 
                          value={currentPass}
                          onChange={handleForm1Change} />
                        </p>
                        <p className='inputs'>
                          <input 
                          type="password" 
                          className='form' 
                          placeholder={translate.prase10[language]} 
                          value={newPass}
                          onChange={handleForm2Change} />
                        </p>
                        <p className='inputs'>
                          <input 
                          type="password" 
                          className='form' 
                          placeholder={translate.prase11[language]} 
                          value={confirmPass}
                          onChange={handleForm3Change} />
                        </p>
                        {errorPasses && <p className="error-message">{errorPasses}</p>}
                        <div className='log-container'>
                                <button className='grid-log login-accept-btn' onClick={() => {handleCheckButton()}}>{translate.prase12[language]} </button> 
                                <button className='grid-log register-route-btn' onClick={() => { navigateTo("../profile")}}>{translate.prase13[language]} </button> 
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    )
}

export default ChangePasswordProfile;