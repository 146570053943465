import { useNavigate } from 'react-router-dom';
import homeBtn from './../imgs/home.png'

const HomeBtn = () => {
    const navigate = useNavigate();
    const navigateBack = () => {
        navigate('/main');
      };
    return <button className='login-decline-btn' onClick={navigateBack}><img src={homeBtn} alt="backToHome"/>Home</button>
    }
export default HomeBtn;