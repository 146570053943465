import { useState, useContext } from "react";
const ModalDate = ({prase, date, cancel}) => {
    const [modal, setModal] = useState(false)
    const state = () => {
        setModal(!modal);
    }
    return (
        <div>
            <button className="modal-show-btn" onClick={state}>Show date</button>
            {modal && 
            <div className="modal-window">
                <div className="error-block"> 
                    <div className="error-massage">
                        <p>{prase} <span className="pointed">{date}</span> </p>
                        <button className="error-btn" onClick={state}>{cancel}</button>
                    </div>
                    
                </div>
            </div>}
        </div>
    )
}
export default ModalDate;
