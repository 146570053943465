import React, { useState, useEffect } from 'react';
const CaptchaImage = () => {
  const [captchaId, setCaptchaId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const domain = url.hostname; 
  useEffect(() => {
    const fetchCaptcha = async () => {
      try {
        const response = await fetch(`https://${domain}/api/user/captcha_id`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const { captcha_id } = data;
        setCaptchaId(captcha_id);
        localStorage.setItem('captcha', captcha_id)
      } catch (error) {
        console.error('Error fetching captcha ID:', error);
      }
    };

    fetchCaptcha();
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (captchaId) {
          const response = await fetch(`https://${domain}/api/user/captcha/${captchaId}`); // URL для получения изображения по captcha_id
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const imageBlob = await response.blob();
          const url = URL.createObjectURL(imageBlob);

          setImageUrl(url);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, [captchaId]);

  return (
    <div style={{textAlign: 'center'}}>
      {imageUrl ? (
        <img className='captcha-image' src={imageUrl} alt="Loaded from API" />
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
};

export default CaptchaImage;
