// LoadingContext.js
import React, { createContext, useState, useContext } from "react";

const LoadingContext = createContext();

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const loader = (param) => {
    setLoading(param);
  };

  return (
    <LoadingContext.Provider value={{ loading, loader }}>
      {children}
    </LoadingContext.Provider>
  );
};
