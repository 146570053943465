import Header from "./Instruments/Header"
export const BankPage = () => {
    return (
        <div className="user">
            <Header/>
            <div className="user-body">
            </div>
        </div>
    )
}
