// AccordionItem.jsx
import React, { useState } from 'react';

const AccordionItem = ({ title, indexSave, index }) => {
  return (
    <div className="accordion-item" style={{fontSize: '30px'}}>
      <button className="accordion-title" onClick={() => {indexSave(index)}}>
        {title}
      </button>
    </div>
  );
};

export default AccordionItem;
