import logo from './imgs/OurLogo.jpg'
import './invoice.css';
export const InvoiceToFill = ({amount, incr, currency, valueInfo}) => {
    return (
        <div className="invoice-container" id="invoice">
            <div className="invoice-header">
              <img src={logo} alt="Global Link LLC" className="invoice-logo"/>
              <div>GLOBAL LINK LLC</div>
              <div>ID: 406420378</div>
              <div>Address: Mountain Makhata 9/2</div>
              <div>Tbilisi Georgia</div>
              <div>e-mail: globlinkllc@gmail.com</div>
              <div>www.globlink.info</div>
            </div>

            <div className="bank-details">
              <div>CARTU BANK JSC</div>
              <div>39a Chavchavadze ave., Tbilisi, Georgia </div>
              <div>SWIFT: CRTUGE22</div>
              <br />
              <div>Account in {valueInfo?.acc}</div>
              <div> {valueInfo?.bank ? `Correspondent bank: ${valueInfo?.bank}`: ''}</div>
              <div>{valueInfo?.swift ? `SWIFT:  ${valueInfo?.swift}`: ''}</div>
              <div>Beneficiary: GLOBAL LINK LLC</div>
            </div>

            <div className="invoice-info">
              <div>Invoice No.{incr}</div>
              <div>Date: {new Date().toLocaleDateString()}</div>
            </div>

            <div className="invoice-table">
              <div className="invoice-table-row">
                <div>Name</div>
                <div>Amount, {currency}</div>
              </div>
              <div className="invoice-table-row">
                <div>Pay TV service</div>
                <div>{amount}</div>
              </div>
            </div>

            <div className="signature">
              <div>Daulet Sutemgenov</div>
              <div>Global Link CEO</div>
            </div>

            <div className="invoice-footer">
              <img src="*" alt="Signature" className="logo"/>
            </div>
        </div>
    )
}