const TrowError = ({message}) => {
    return(
        <div className="modal-window">
            <div className="error-block"> 
                <div className="error-massage">
                    <h1>Error!</h1>
                    <p>{message}</p>
                    <button className="error-btn" onClick={() => {window.location.reload()}}>Retry</button>
                </div>
            </div>
        </div>
    )
}

export default TrowError;