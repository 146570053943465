import { useNavigate } from "react-router-dom";
const Error = () => {
    const navigate = useNavigate();
    const locateTo = () => {
        navigate('/')
    } 
    return(
        <div style={{width: '100%', height: '100vh', display: 'flex', alignItems:'center', justifyContent: 'center' }}>            
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <h1>Page is not found</h1>
                <button style={{border: 0, borderRadius: '5px', backgroundColor: 'black', color: 'white', padding: '20px'}} onClick={locateTo}>Back to main page</button>
            </div>
        </div>
    )
}
export default Error;