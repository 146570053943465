import { useNavigate } from "react-router-dom";
const Success = ({translate, language}) => {
    const navigate = useNavigate()
    return(
        <div className="modal-window">
        <div className="error-block"> 
            <div className="error-massage">
                <h1>{translate.register.message.title[language]}</h1>
                <p>{translate.register.message.text[language]}</p>
                <button className="error-btn" onClick={() => {navigate('/main/login')}}>{translate.register.message.button[language]}</button>
            </div>
        </div>
        </div>
    )
}

export default Success;