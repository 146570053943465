import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
//pages
import About from './components/main-menu/About';
import Login from './components/main-menu/Login';
import Register from './components/main-menu/Register';
import ChangePasswordRequest from './components/main-menu/ChangePasswordRequest';
import NewPassword from './components/main-menu/ChangePassword';

// import AddDecoder from './components/user-menu/AddDecoder';
import Balance from './components/user-menu/Balance';
import Profile from './components/user-menu/Profile';
import PasswrdChange from './components/user-menu/PasswordChange';

import { BankPage } from './components/user-menu/BankPage';
import Error from './Error';


//css
import './css/main-menu/App.css';
import './css/main-menu/Service.css';
import './css/main-menu/Footer.css';
import './css/main-menu/Login.css';
import './css/main-menu/Captcha.css';
import './css/main-menu/tariffs.css';
import './css/user-menu/PacketBlock.css'
import './css/user-menu/Adaptive.css';
import './css/user-menu/UserBody.css';
import './css/user-menu/Decoder.css';
import './css/user-menu/CountryList.css';
import './css/user-menu/Header.css';    
import './css/user-menu/Modal.css';

const Routers = () => {
    const [systemIn, setSystemIn] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(new URL(window.location.href).search);
    const [dcr_id, setDecId] = useState('');
    const [decoders, setDecoders] = useState('error');
    const domain = new URL(window.location.href).hostname;

    const fetchUser = async () => {
      try {
        const response = await fetch(`https://${domain}/api/user`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setDecoders(data ? data?.decoders : 'error');
        setSystemIn(true);
      } catch (error) {
        setSystemIn(false);
      }
    };
    useEffect(() => {
      fetchUser();
    }, [location]); 
    const putdec = async (decoder) => {
      const requestOptions = {
        method: "PUT",
        headers:{'Content-Type':'application/json'},
        body: JSON.stringify({decoder_id: decoder}) 
      }
      try{
        const respond = await fetch(`https://${domain}/api/user/decoders`, requestOptions);
        if(!respond.ok){
          throw new Error();
        }  
      }
      catch(error){
        console.error(error);
      }
    }
    
    useEffect(() => {
      const setSystemStatus = async () => {
        if(location.pathname === '/add-dcr') setDecId(urlParams.get('dcr_id'));
        try {
          const response = await fetch(`https://${domain}/api/user`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          if(location.pathname === '/add-dcr') {
            navigate('/main/balance');
            putdec(urlParams.get('dcr_id'));
          }
          setSystemIn(true);
        } catch (error) {
          if(location.pathname === '/add-dcr'){
            navigate('/main/login');
            setSystemIn(false);
          }
        }
      }
      setSystemStatus();
    }, [])
    return(
        <Routes> 
            <Route path="for-bank-page" element={<BankPage />}/>
            <Route path="/" element={<Navigate to="/main" />} /> 
            <Route path="/main" element={<About signStatus={systemIn} decoders={decoders}/>} />
            <Route path="/reset" element={<NewPassword />} />  
            <Route
            path="/main">            
                <Route path="login" element={<Login dcr_id={dcr_id} putdec={putdec}/>} />
                <Route path="register" element={<Register currentPath="/main"/>} />
                <Route path="change-password-request" element={<ChangePasswordRequest currentPath="/main"/>} />
                <Route path="profile" element={<Profile />}/>
                <Route path="password-change" element={<PasswrdChange />}/>
                <Route path="balance" element={<Balance />} />
                
            </Route>  
            <Route path='*' element={<Error/>}/>
        </Routes>
    )
}

export default Routers;