import mail from './../imgs/email.png'
const Email = () => {
    const email = 'globlinkllc@gmail.com';
    const subject = 'Деловое предложение'; 
    const body = 'Приветствую, я представитель из компании ...! Хотел предложить вам свои услуги в ...'; 
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    return(
        <div>
            <img className='contactIcons' src={mail} alt="mail" /> <a href={mailtoUrl}>globlinkllc@gmail.com</a>
        </div>
    )
}

export default Email;