import React, { useEffect, useState } from 'react';
import translate1 from './../../../Translate/balancePage.json';
function Button({min, balance, language, setAmount}) {
  const [buttons, setButtons] = useState([
    { value: 1, isHovered: false, isClicked: false},
    { value: 2, isHovered: false, isClicked: false},
    { value: 3, isHovered: false, isClicked: false},
    { value: 12, isHovered: false, isClicked: false},
  ]);
  const [inputValue, setInputValue] = useState(0);

  useEffect(() => {
    const fillInfo = () => {
      const updated = [...buttons];
      if(balance < min){
        updated[0].value = (min - balance).toFixed(2);
        updated[1].value = (min * 2 - balance).toFixed(2);
        updated[2].value = (min * 3 - balance).toFixed(2);
        updated[3].value = (min * 12 - balance).toFixed(2);
        setInputValue(updated[0].value);
      }else{
        updated[0].value = 0;
        updated[1].value = 0;
        updated[2].value = 0;
        updated[3].value = 0;
        setInputValue(0);
      }
      updated[0].isClicked = true;
      updated[1].isClicked = false;
      updated[2].isClicked = false;
      updated[3].isClicked = false;
      setButtons(updated);
    }
    fillInfo();
  }, [balance, min])
  const handleInputChange = (e) => {
    const value = e.target.value;
    if(!isNaN(value)) { 
      setInputValue(value);
    }
  }

  const handleMouseEnter = (index) => {
    const updatedButtons = [...buttons];
    updatedButtons[index].isHovered = true;
    setButtons(updatedButtons);
  };

  const handleMouseLeave = (index) => {
    const updatedButtons = [...buttons];
    updatedButtons[index].isHovered = false;
    setButtons(updatedButtons);
  };

  const handleClick = (index) => {
    const updatedButtons = [...buttons];
    updatedButtons.forEach((button, i) => {
      if (i === index) {
        button.isClicked = true;
      } else {
        button.isClicked = false;
      }
    });
    setButtons(updatedButtons);
    setInputValue(buttons[index].value); //if(balance > buttons[index].value)
  };

  useEffect(() => {
    setAmount(inputValue);
  }, [inputValue])
  return (
    <div>
        <p></p>
        <p>{translate1.prase3[language]} </p>
        <p>
            {buttons.map((button, index) => (
                <button
                  key={index}
                  className={`user-tarif-buttons ${button.isHovered ? 'hovered' : ''} ${button.isClicked ? 'clicked' : ''}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  onClick={() => handleClick(index)}
                >
                  {translate1['prase' + (index + 4)][language]}
                </button>
            ))}
        </p>
        {
          (inputValue === buttons[0].value ||
          inputValue === buttons[1].value ||
          inputValue === buttons[2].value || 
          inputValue === buttons[3].value) ? <span style={{fontSize:'14px', position: 'absolute'}}>{translate1.prase8[language]}</span> : ''}
      <p className='user-tarif-transfer'>
        ₾
        <input 
        type="number"
        value={inputValue} 
        onChange={handleInputChange} />
      </p>
      
    </div>
  );
}

export default Button;
