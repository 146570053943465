import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CaptchaImage from './Instruments/CaptchaImage'
import HomeBtn from './Instruments/HomeBtn'
import ThrowError from './Instruments/TrowError';
import translate from './../../Translate/resetPassword.json';

const ChangePasswordLogin = ({ currentPath }) => {
    const navigate = useNavigate();
    const navigateTo = (path) => {
        if (currentPath === "/billing") navigate(`${currentPath}/subscribers`);
        else navigate(`${currentPath}/${path}`);
    };

    const [language, setLanguage] = useState('');
    useEffect(() => {
      setLanguage(JSON.parse(localStorage.getItem('choosen-language')));
    }, [])

    const [currentPass, setCurrentPass] = useState('');
    const [errorCurrent, setErrorCurrent] = useState('');

    const [captchaForm, setCaptchaForm] = useState('');
    const [errorCaptcha, setErrorCaptcha] = useState(false);

    const handleCaptchaFormChange = (e) => {
        setCaptchaForm(e.target.value);
    }
    const handleForm2Change = (e) => {
        setCurrentPass(e.target.value);
    }
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const domain = url.hostname; 
    async function checkCaptcha() {
        const id = localStorage.getItem('captcha')
        const dataToSend = {
          email: currentPass,
          captcha_id: id,
          captcha_reply: captchaForm, 
        };
        console.log(dataToSend, JSON.stringify(dataToSend) );
        const apiUrl = `https://${domain}/api/user/reset_request`;
        const requestOptions = {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dataToSend)  
        };
      
        try {
          const response = await fetch(apiUrl, requestOptions);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          localStorage.setItem('captcha', '');
          navigateTo("login");
        } catch (error) {
          console.error('Ошибка при запросе к API:', error);
          setErrorCaptcha(true);
        }
      }

    const handleCheckButton = () => {
        let isValidForm2 = true;
        if (currentPass === '') {
            isValidForm2 = false;
            setErrorCurrent('Complete the form');
        } else {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (!emailPattern.test(currentPass)) {
                isValidForm2 = false;
                setErrorCurrent('Not an email');
            }else {
                setErrorCurrent('');
            }
        }
        console.log(captchaForm)
        if(isValidForm2){
            checkCaptcha();
        }
    }
    return(
        <div>
            <div className='main for-log'>
            {errorCaptcha && <ThrowError message={""}/>}
            <HomeBtn/>
                <div className='log'>
                    <div className='logs'>
                        <h2 className='main-pages-titles'>{translate.reset.title[language]}</h2>
                        <p className='inputs'>
                          <input 
                          type="text" 
                          className='form' 
                          placeholder={translate.reset.placeholder1[language]}
                          value={currentPass}
                          onChange={handleForm2Change} />
                        </p>
                        {errorCurrent && <p className="error-message">{errorCurrent}</p>}
                        <CaptchaImage/>
                        <p>
                            <input 
                              type="text" 
                              className='form' 
                              placeholder={translate.reset.placeholder2[language]} 
                              value={captchaForm}
                          onChange={handleCaptchaFormChange} />
                        </p>
                        <p>{translate.reset.prase1[language]}</p>
                        <div className='log-container'>
                            <button className='grid-log login-accept-btn' onClick={() => {handleCheckButton()}}>{translate.reset.button1[language]}</button> 
                            <button className='grid-log register-route-btn' onClick={() => {navigateTo("login")}}>{translate.reset.button2[language]}</button> 
                        </div>
                        
                    </div>
                </div> 
            </div> 
        </div>
    )
}

export default ChangePasswordLogin;

// <div style={{
//     position: 'absolute',
//     width: '100%',
//     height:'100vh',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     top: 0,
//     left: 0,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
// }}> 
//     <div style={{
//         textAlign:'center',
//         backgroundColor: '#FAFAFA',
//         borderRadius: '5px',
//         border: 0,
//         padding: '30px',
//         color: '#07689F',
//     }}>
//         <h1>characters aren't correct</h1>
//         <button style={{
//             borderRadius: '5px',
//             border: 0,
//             backgroundColor: '#07689F',
//             color: '#FAFAFA',
//             padding: '10px',
//             fontSize: '15px'
//         }} onClick={() => {window.location.reload()}}>Retry</button>
//     </div>
// </div>