import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CaptchaImage from './Instruments/CaptchaImage';
import HomeBtn from './Instruments/HomeBtn';
import ThrowError from './Instruments/TrowError'
import RegisterModal from './Instruments/RegisterModal';
// import './../../css/main-menu/Login.css'
import translate from './../../Translate/loginRegister.json';

const Register = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
      navigate(path);
  };

  const [language, setLanguage] = useState('');
  useEffect(() => {
    setLanguage(JSON.parse(localStorage.getItem('choosen-language')));
  }, [])

  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [captchaForm, setCaptchaForm] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false);

  const handleCaptchaFormChange = (e) => {
      setCaptchaForm(e.target.value);
  }
  const handleMailFormChange = (e) => {
      setEmail(e.target.value);
  }
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const domain = url.hostname; 
  async function checkCaptcha() {
      const id = localStorage.getItem('captcha')
      const dataToSend = {
        email: email,
        captcha_id: id,
        captcha_reply: captchaForm, 
      };
      const apiUrl = `https://${domain}/api/user/register`;
      const requestOptions = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)  
      };
    
      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data?.result);
        }
        localStorage.setItem('captcha', '');
        setSuccess(true);
      } catch (error) {
        const message = error.message;
        setErrorMessage(message);
        setErrorModal(true);
        console.error("Unhandled error:", error);
      }
    }
  const handleCheckButton = () => {
      let isValidForm = true;
      if (email === '') {
          isValidForm = false;
          setErrorEmail('Complete the form');
      }else if(!isChecked){
        isValidForm = false;
        setErrorEmail('Click on checkbox');
      } else {
          const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if (!emailPattern.test(email)) {
              isValidForm = false;
              setErrorEmail('Not an email');
          }else {
              setErrorEmail('');
          }
      }
      console.log(captchaForm)
      if(isValidForm && isChecked){
        checkCaptcha();
      }
  }

  return(
      <div>
          <div className='main for-log'>
          {errorModal && <ThrowError message={errMessage}/>}
          {success && <RegisterModal translate={translate} language={language}/>}
          
          <HomeBtn/>
              <div className='log'>
                  <div className='logs'>
                      <h2 className='main-pages-titles'>Register</h2>
                      <p className='inputs'>
                        <input 
                        type="text" 
                        className='form' 
                        placeholder={translate.register.placeholder1[language]} 
                        value={email}
                        onChange={handleMailFormChange} />
                      </p>
                      <CaptchaImage/>
                      <p>
                          <input 
                            type="text" 
                            className='form' 
                            placeholder={translate.register.placeholder2[language]} 
                            value={captchaForm}
                        onChange={handleCaptchaFormChange} />
                      </p>
                      <p><input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                        {translate.register.prase1[language]} 
                        <a href="/static/pdfs/Terms-of-Service.pdf" target="_blank" rel="noopener noreferrer"> <span className='pointed'> {translate.register.prase2[language]} </span></a>
                        {translate.register.prase3[language]}
                      </p>
                      {errorEmail && <p className="error-message">{errorEmail}</p>} 
                      <div className='log-container'>
                          <button className='grid-log login-accept-btn' onClick={() => {handleCheckButton()}}>{translate.register.button1[language]}</button> 
                          <button className='grid-log register-route-btn' onClick={() => {navigateTo("../login")}}>{translate.register.button2[language]}</button> 
                      </div>
                  </div>
              </div> 
          </div> 
      </div>
  )
}

export default Register;