import React from 'react';
import { usePayment } from './script';
import visa_logo from './imgs/visa.png';
import mastercard_logo from './imgs/card.png';
import './../../../css/user-menu/Modal.css';
export const CardModal = ({ translate, language, currencies, domain, minAmount }) => {
  const {
    currency,
    modal,
    amount,
    errMsg,
    state,
    handleChangeInput,
    handleChangeCurrency,
    pay,
  } = usePayment({ currencies, domain, minAmount });

  return (
    <div>
      <button className="user-profile-form-btn" onClick={state}>
        <img src={visa_logo} alt="visa" />
        <img src={mastercard_logo} alt="mastercard" />
      </button>
      {modal && (
        <div className="modal-window">
          <div className="error-block">
            <div className="error-massage">
              <div className="block-to-flex-column">
                <label htmlFor="input">Amount:</label>
                <input type="text" value={amount} onChange={handleChangeInput} />
                {errMsg && <span>Not a number or not capable number for payment</span>}
                <label htmlFor="select">Currency:</label>
                <select onChange={handleChangeCurrency} value={currency}>
                  {currencies.map((option, index) => (
                    <option key={index}>{option}</option>
                  ))}
                </select>
              </div>
              <button className="error-btn" onClick={pay}>
                Go to online bank page
              </button>
              <button className="error-btn" onClick={state}>
                {translate.prase20[language]}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
