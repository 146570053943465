import { useState, useEffect } from "react";
import { InvoiceToFill } from "./Invoice";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
export const useInvoice = ({currencies, domain}) => {
    const [valueInfo, setValueInfo] = useState({});
    const downloadPDF = async () => {
        let id = 0;
        const dataToSend = {
          amount: amount, 
	        currency: currency
        };
        const requestOptions = {
          method: 'POST', 
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(dataToSend)  
        };
        try {
          const response = await fetch(`https://${domain}/api/user/invoice`, requestOptions);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          id = data?.invoice_id;
        } catch (error) {
          console.error('Ошибка при запросе к API:', error);
        }
        const htmlString = ReactDOMServer.renderToString(<InvoiceToFill amount={amount} incr={id} currency={currency} valueInfo={valueInfo}/>);
        const hiddenContainer = document.createElement('div');
        hiddenContainer.style.position = 'absolute';
        hiddenContainer.style.left = '-9999px';
        hiddenContainer.innerHTML = htmlString;
        document.body.appendChild(hiddenContainer);
        try {
          const canvas = await html2canvas(hiddenContainer);
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save(`invoice.pdf`);
        } catch (error) {
          console.error('Error generating PDF:', error);
        } finally {
          document.body.removeChild(hiddenContainer);
          state();
        }
      };
      const [modal, setModal] = useState(false);
      const state = () => {
        setModal(!modal);
      };
    
      const [amount, setAmount] = useState(0.00);
      const handleChangeInput = (e) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value)){
          setAmount(parseFloat(value.toFixed(2)));
        }
      };
    
      const [currency, setCurrency] = useState(currencies[0]);
      const handleChangeCurrency = (e) => {
        setCurrency(e.target.value);
      };
      const identifyValue = () => {
        switch (currency) {
          case "USD": 
            return {
              acc: "USD: GE4CR0000009536683612",
              bank: "JSC Bank of Georgia",
              swift: "BAGAGE22"
            }
          case "EUR": 
            return {
              acc: "EURO: GE41CR0000009536683612",
              bank: "Landesbank Baden-Wuerttemberg Stuttgart Germany",
              swift: "SOLADEST"
            }
          case "GEL":
            return {
              acc: "GEL: GE41CR0000009536683602",
            }
          default: return {}
        }
      }
    useEffect(() => {
      setValueInfo(identifyValue());
    }, [currency])

      return {
        currency,
        modal,
        state,
        handleChangeInput,
        handleChangeCurrency,
        downloadPDF
      }
}