import { useState, useEffect } from 'react';
import Header from "./Instruments/Header";
import ButtonMenu from "./Instruments/ButtonForTarifPlan";
import Modal from "./Instruments/Modal"
import { useNavigate } from 'react-router-dom';
import './CardModal/style.css'
import translate from './../../Translate/balancePage.json';
import months from './../../Translate/months.json';
import { Invoice } from './InvoiceModal';
import { CardModal } from './CardModal';
import { LoadingModal } from './LoadModal';
import { useLoading } from "./LoadModal/script";
const Balance = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState('');
  useEffect(() => {
    setLanguage(JSON.parse(localStorage.getItem('choosen-language')));
  }, [navigate])
  /////////////////////////////////////////////////////////////
  //current domain
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const domain = url.hostname; 
  
  /////////////////////////////////////////////////////////////
  //fetching info
  const [balance, setBalance] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [status, setCountryChooseStatus] = useState(true); 
  const [decoders, setDecoders] = useState(null);
  const [modal, showModal] = useState(false); 
  const [isClicked, setIsClicked] = useState([[], []]);
  const [min, setMin] = useState(0);
  const [amount, setAmount] = useState(0);

  /////////////////////////////////////////////////////////////
  //fetching functions
  const fetchCurrencies = async () => {
    try{
        const response = await fetch(`https://${domain}/api/bank/currencies`);
        if (!response.ok) throw new Error(response.status);
        const currencyData = await response.json();
        setCurrencies(currencyData.currencies);
    }catch(e){
      console.error(e);
    }
  }

  const fetchDecoders = async () => {
    try{
      const response = await fetch(`https://${domain}/api/user/decoders`);
      if(!response.ok) {throw new Error('Network response was not ok');}
      const data = await response.json();
      const decs = data.decoders;
      const isClick = decs?.map((decoder) => decoder.packs?.map((pack) => {
        if(!pack.one_time && pack.expire){
          return true;
        }
        return false;
      })) || [[],[]];
      setIsClicked(isClick);
      setDecoders(decs);
    }
    catch(error){
      console.error('Error fetching', error);
      
    }
  }
  const {loader} = useLoading();
  const fetchBalance = async () => {
    loader(true);
    try {
      const response = await fetch(`https://${domain}/api/user`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setBalance(data.balance);
      setCountryChooseStatus(null ? false : true);
      loader(false);
    } catch (error) {
      console.error('Error fetching', error);
      navigate('/main');
    }
  };

  const fetchChangings = async (item) => {
    const apiUrl = `https://${domain}/api/user/decoders`;
    const requestOptions = {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: item || {},  
    };
    try{
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) { throw new Error('Error')}
      fetchDecoders();
      fetchBalance();
    }
    catch(error){
      console.error(error);
    }
  }
  /////////////////////////////////////////////////////////////
  //interface setters
  const converter = (param) => {
    if (!param.expire) return "Inactive";
    // else return param.expire;
    const arr = param.expire.split('-');
    return months[language][arr[1] - 1] + " " + arr[2] + ", " + arr[0]
  }

  const toogleChoose = (value, index, ind) => {
      const updated = [...isClicked];
      updated[index][ind] = value;
      setIsClicked(updated);
  }
  const deleteAll = (index) => {
      const updated = [...isClicked];
      updated[index] = isClicked[index].map(elem => elem = false)
      setIsClicked(updated);
  }

  //fetch changes
  const handleSubmit = (index) => {
    const packs = decoders[index].packs.map((pack, ind) => {  
      if(isClicked[index][ind]){
        return pack.pack_id;
      }
    }).filter((item) => item !== undefined)
    const fetchItem = {
        decoder_id: decoders[index].decoder_id,
        packs: packs,
    }
    fetchChangings(JSON.stringify(fetchItem));
  };

  const adaptiveInfo = (pack) => {
    if(window.innerWidth < 759) 
      return <td><Modal cancel={translate.prase20[language]} prase={pack.one_time ? translate.prase15[language] : translate.prase14[language]} date={converter(pack)}/></td>
    else
      return <td>{pack.one_time ? translate.prase15[language] :  translate.prase14[language]} <span className="pointed">{converter(pack)}</span></td>
  }

  /////////////////////////////////////////////////////////////
  //useEffects
  useEffect(() => {
    fetchDecoders();
    fetchCurrencies();
    fetchBalance();
    showModal(balance < min); 
  }, [navigate]);

  useEffect(() => {
    showModal(balance < min); 
  }, [balance, min]);

  useEffect(() => {
    const arr = decoders?.map((decoder) => decoder.packs?.map((pack) => {
      if(!pack.one_time && pack.expire){
        return pack.cost || 0;
      }
      return 0;
    }))
    const sum = arr?.reduce((acc, val) => acc + val?.reduce((a, v) => a + v, 0), 0)
    setMin(sum);
  }, [decoders])
  return(
        <div className="user">
            <Header/>
            <div className="user-body">
                <LoadingModal/>
                <div className="balance-container-box" style={!decoders ? {top: "-5vh"} : {height: "100%"}}>
                  <div className='balance-container'>
                      <div className="user-profile-block-container">
                          <div className="user-profile-right-column">
                              <p>{translate.prase1[language]} <span className="pointed">{balance} ₾</span></p> {/**/}
                              {modal && <div className="error-block"> 
                                <div className="error-massage">
                                  <h1> {translate.prase18[language]}</h1>
                                  <p>{translate.prase19[language]}</p>
                                  <button className="error-btn" onClick={() => {showModal(false)}}>{translate.prase20[language]}</button>
                                </div>
                              </div>} 
                              <div style={{
                                  display: 'flex',
                                  maxWidth: '260px',
                                  flexDirection: 'column'
                              }}>
                                  <CardModal currencies={currencies} translate={translate} language={language} domain={domain} minAmount={amount}/>
                                  <Invoice translate={translate} language={language} domain={domain}/>
                              </div>
                          </div>
                          <div className="user-profile-right-column">
                              <ButtonMenu min={min} balance={balance} language={language} setAmount={setAmount}/>
                          </div>
                      </div>
                  </div>
                </div> 
                <div className="packets-list-block">
                     {decoders && decoders.map((decoder, index) => (  
                         <div className="packets-list-card" key={index}>
                             <div className="packets-list-card-header">
                                 <div>{translate.prase9[language]} <span className="pointed">{decoder.decoder_id}</span></div>
                                 <div><button disabled={!status} className={status ? "packets-list-apply" : ""} onClick={() => {if(status)handleSubmit(index)}}>{translate.prase17[language]}</button></div> {/**/}
                             </div>
                             <table style={{maxWidth: '100%', minWidth: '100%'}}>
                                 <thead><tr style={{textAlign:'center'}}><td colSpan={4}>{translate.prase21[language]}</td></tr></thead>
                                 <tbody>
                                   {decoder.packs?.map((pack, ind) => (
                                       <tr key={ind}>
                                           <td className="td-btn"><input style={status ? { boxShadow: '1px 1px 1px 1px #00000080' } : {}} type="checkbox" disabled={!status} checked={isClicked[index][ind]} onChange={(e) => {toogleChoose(e.target.checked, index, ind)}}/></td>{/*isClicked[index][ind]*/}
                                           <td>{pack.name}</td>
                                           <td>GEL <span className="pointed">{pack.cost}</span> {translate.prase10[language]} </td>
                                           {adaptiveInfo(pack)}
                                       </tr>   
                                   ))}
                                 </tbody>
                                 <tfoot><tr className="clickable" style={{textAlign:'center'}} onClick={() => {if(status)deleteAll(index)}}><td colSpan={4}>{translate.prase16[language]}</td></tr></tfoot> {/**/}
                             </table>
                         </div>
                     ))}
                </div>
            </div>    
            
        </div>
    )
  }
export default Balance;


//<div className="packets-list-block pc">
                     //{decoders && decoders.map((decoder, index) => (
                        //  <div className="packets-list-card" key={index}>
                        //      <div className="packets-list-card-header">
                        //          <div>{translate.prase9[language]} <span className="pointed">{decoder.decoder_id}</span></div>
                        //          <div><button disabled={!status} className={status ? "packets-list-apply" : ""} onClick={() => {if(status)handleSubmit(index)}}>{translate.prase17[language]}</button></div> {/**/}
                        //      </div>
                        //      <table style={{maxWidth: '100%', minWidth: '100%'}}>
                        //          <thead><tr style={{textAlign:'center'}}><td colSpan={4}>{translate.prase21[language]}</td></tr></thead>
                        //          <tbody>
                        //            {decoder.packs?.map((pack, ind) => (
                        //                <tr key={ind}>
                        //                    <td className="td-btn"><input style={status ? { boxShadow: '1px 1px 1px 1px #00000080' } : {}} type="checkbox" disabled={!status} checked={isClicked[index][ind]} onChange={(e) => {toogleChoose(e.target.checked, index, ind)}}/></td>{/*isClicked[index][ind]*/}
                        //                    <td>{pack.name}</td>
                        //                    <td>USD <span className="pointed">{pack.cost}</span> {translate.prase10[language]} </td>
                        //                    <td>{pack.one_time ? translate.prase15[language] :  translate.prase14[language]} <span className="pointed">{converter(pack)}</span></td>
                        //                </tr>   
                        //            ))}
                        //          </tbody>
                        //          <tfoot><tr className="clickable" style={{textAlign:'center'}} onClick={() => {if(status)deleteAll(index)}}><td colSpan={4}>{translate.prase16[language]}</td></tr></tfoot> {/**/}
                        //      </table>
                        //  </div>
                    // ))}
                //</div>