import React from 'react';
import point from './../imgs/placeholder.png'
import telegram from './../imgs/telegram.png'
import whatsapp from './../imgs/whatsapp.png'
import partner1 from './../imgs/Global_site.jpg'
import partner2 from './../imgs/Trophy_site.jpg'
import { saveAs } from 'file-saver';
import Email from "./Email";

import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import translate from './../../../Translate/footer.json';

const Footer = ({language}) => {
  const handleDownload = () => {
    const pdfPath = process.env.PUBLIC_URL + '/static/pdfs/Terms-of-Service.pdf';
    saveAs(pdfPath, 'Terms-of-Service.pdf');
  };
  return (
      <div className="footer">
        <div className="container">
          <div className="column">
            <h3><span className='pointed'>{translate.prase1[language]}</span></h3>
            <p>
              <img className='contactIcons' src={point} alt="point" /> <a href="https://maps.app.goo.gl/a5h8ci1WPnjyfFfq9">{translate.prase2[language]}</a>
            </p>
            <Email />
            <p>
              <img className='contactIcons' src={telegram} alt="telegram" /> <img src={whatsapp} alt="telegram" />(+995) 558 252808 
            </p>
          </div>
          <div className="column">
            <h3><span className='pointed'>{translate.prase3[language]}</span></h3>
            <p>
              <Button onClick={handleDownload} className='footer-btns dowload-btn' component="label" variant="contained" startIcon={<CloudDownloadIcon />}>
                Document.pdf
              </Button>
            </p>
          </div>
          <div className="column">
            <h3><span className='pointed'>{translate.prase4[language]}</span></h3>
            <p><a href="https://www.eutelsat.com/en/satellites/eutelsat-36-east.html" className='partner'>Eutelsat.com</a></p>
            <p><img className='partner-img' src={partner1} alt="partners" /></p>
          </div>
          <div className="column">
            <h3><span className='pointed'>{translate.prase4[language]}</span></h3>
            <p><a href="https://dvb4all.com/" className='partner'>Dvb4all.com</a></p>
            <p><img className='partner-img' src={partner2} alt="partners" /></p>
          </div>
        </div>
      </div>
    );
  };

export default Footer;